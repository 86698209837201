import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionCheckAuthentication } from './root-store/auth/actions';
import { State } from './root-store/state';

@Component({
    selector: 'app-root',
    template: ` <ngx-ui-loader></ngx-ui-loader>
        <router-outlet></router-outlet>`,
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.store.dispatch(new ActionCheckAuthentication());
    }
}
