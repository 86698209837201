import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { RootStoreModule } from './root-store/root-store.module';

import { HomeComponent } from './components/home/home.component';
import { LayoutModule } from './components/layout/layout.module';

// Modules
import { SharedModule } from './modules/shared.module';

// Services
import { TourwowBaseService } from 'app/services/tourwow-base.service';

// Factories
import { appBaseHrefFactory } from './shared/factories/app.factory';

// Third Party
import { ModalModule } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { thBeLocale } from 'ngx-bootstrap/locale';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MainComponent } from './theme/main/main.component';
import { MainComponent as MainV2Component } from './theme/v2/main/main.component';
import { SignupModule } from './modules/signup/signup.module';
import { AuthModule } from './modules/auth/auth.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsPosition: POSITION.centerCenter,
};

defineLocale('thbe', thBeLocale);

@NgModule({
    declarations: [AppComponent, MainComponent, MainV2Component, HomeComponent],
    imports: [
        HttpClientModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        TransferHttpCacheModule,

        EffectsModule.forRoot([AppEffects]),
        RootStoreModule,
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        ModalModule.forRoot(),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),

        AppRoutingModule,
        LayoutModule,
        SharedModule,
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: appBaseHrefFactory,
            deps: [TourwowBaseService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
