<div class="quantity-textbox" *ngIf="!hidden" [ngClass]="{ disabled: disabled }">
    <span [ngClass]="{ disabled: quantity <= min }" (click)="subtract()" class="control">-</span>
    <input
        [(ngModel)]="quantity"
        (ngModelChange)="keydown($event)"
        class="input"
        type="text"
        maxlength="4"
        max="{{ max }}"
        min="{{ min }}"
        maxlength="{{ maxlength }}"
        [attr.disabled]="disabled ? true : null"
        [options]="{ prefix: '', precision: 0, align: 'center', allowNegative: false }"
        currencyMask
    />
    <span [ngClass]="{ disabled: quantity >= max }" (click)="add()" class="control">+</span>
</div>
