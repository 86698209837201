import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'app/services/breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    public breadcrumbText: string;
    constructor(private breadcrumbService: BreadcrumbService) {}

    ngOnInit() {
        this.breadcrumbService.text.subscribe((updatedTitle) => {
            this.breadcrumbText = updatedTitle;
        });
    }
}
