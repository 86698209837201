import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent {
    @Output() expanded = new EventEmitter<boolean>();
    isExpandedNav = true;

    constructor() {}

    onToggleExpandNav(): void {
        this.isExpandedNav = !this.isExpandedNav;
        this.expanded.emit(this.isExpandedNav);
    }
}
