import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { BreadcrumbV2Component } from './breadcrumb-v2/breadcrumb-v2.component';

import { SharedModule } from 'app/modules/shared.module';

@NgModule({
    declarations: [HeaderComponent, FooterComponent, NavMenuComponent, BreadcrumbV2Component],
    imports: [CommonModule, RouterModule, SharedModule],
    exports: [HeaderComponent, FooterComponent, NavMenuComponent, BreadcrumbV2Component],
})
export class LayoutModule {}
