<div class="breadcrumb-v2 box">
    <div class="container p-0 d-flex justify-content-round align-items-center mx-lg-auto mx-0">
        <div class="seller">
            <div class="d-flex align-items-center">
                <div
                    *ngIf="supplierInfo$ | async as supplierInfo"
                    class="seller-logo d-none d-lg-block text-center py-2"
                >
                    <img class="logo-image" [src]="supplierInfo?.image_path" />
                </div>
                <div class="seller-breadcrumb">
                    <!-- breadcrumb -->
                    <div id="breadcrumb" class="breadcrumb breadcrumb-orange twp-text-m py-3 ml-3">
                        <ul class="d-flex align-items-center">
                            <li>
                                <span>Online Booking</span>
                            </li>
                            <li class="d-flex align-items-end">
                                <img src="assets/icons/ico-arrow-right.svg" class="mx-3" />
                                <span>{{ breadcrumbText }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- powerby -->
        <div class="powered-by ml-auto d-none d-lg-block">
            <span class="twp-text-m-bold">Powered by</span>&nbsp;<img
                src="assets/images/logo-twp-top.svg"
                width="91"
                height="21"
                alt=""
            />
        </div>
    </div>
</div>
